import { CardinalEvents } from './cardinal.events';

class CardinalIntegration {
    constructor() {
        // Catch our submit event being fired from the document and intercept it
        // The event is actually attached to the submit button, but we bubble it
        // to save us having to find the submit button, realistically there is only ever one submit on the page
        // so we don't need to hook up to multiple submit buttons.
        // This is the code that sends the card details to cardinal
        document.addEventListener("beforeSubmit", function (event) {
            //var submitButton = document.getElementById(event.detail.submitButtonId);

            // If the Cardinal SDK has not been loaded, disable 3DS
            if (typeof (Cardinal) !== "undefined") {
                //Call cardinal
                window.CardinalIntegration.start3ds();
            } else {
                // This is what we do if cardinal is turned off, we just fire the event to submit the page.
                window.CardinalIntegration.fireSubmit();
            }
        });
    }

    // Hook up our events, and attach JS UI hooks
    init(cardinalJwt) {
        // If the Cardinal SDK has not been loaded, don't try to enable 3DS
        if (typeof(Cardinal) !== "undefined") {
            Cardinal.configure({
                logging: {
                    level: "off"
                    // debug:"verbose"
                }
            });

            // init with our JWT
            Cardinal.setup("init", { jwt: cardinalJwt });
            Cardinal.on('payments.setupComplete', CardinalEvents.onSetupComplete);
            Cardinal.on("payments.validated", CardinalEvents.onPaymentsValidated);
        }
    }

    // Fires the submit button 
    fireSubmit() {
        let event = new CustomEvent("doSubmit", { bubbles: true });
        document.dispatchEvent(event);
    }

    // Update the cardinal JWT, i.e. the amount changed on the donation page, so we need to
    // send to cardinal a JWT with the new amount.
    // -NB - we dont use this at the moment
    updateJWT(jwt) {
        if (jwt === undefined) {
            // if they didnt pass in a JWT, grab it from the DOM
            jwt = document.getElementById('ThreeDSJWTContainer').getAttribute("data-value");
        }
        Cardinal.trigger('jwt.update', jwt);
    }

    // This is out method that actually kicks everything off and starts the cardinal 3DS check
    start3ds() {
        // Check the payment method type, if it's not a credit card fire the submit button
        let paymentMethodSelector = document.querySelector("select[id$='_PaymentMethodDropDown']");
        if (paymentMethodSelector === null || paymentMethodSelector === undefined || !paymentMethodSelector.options[paymentMethodSelector.selectedIndex].value.toLowerCase().startsWith("creditcard")) {
            window.CardinalIntegration.fireSubmit();
            return;
        }

        // The editable amount, from PaymentCreator
        // the amount in the JWT can be overridden when calling .start if we need it to be.
        // most of the time, our implementation leaves the amount in the token
        let paymentAmountEditInput = document.querySelector("input[data-3ds-field='PaymentAmountEdit']") || null;
        var jwt = null;
        
        if (paymentAmountEditInput !== null) {
            jQuery.ajax(gWebRoot + "/api/ThreeDSTokenService?amount=" +
                paymentAmountEditInput.value,
                {
                    type: "get",
                    contentType: "application/json",
                    headers: {
                        "RequestVerificationToken": document.getElementById("__RequestVerificationToken").value
                    },
                    success: function(data) {
                        jwt = data.Items.$values[0].SignedJwt;
                        window.CardinalIntegration.updateJWT(jwt);
                    },
                    error: function(data) {
                        // TODO Add in error handling in case the call fails
                    },
                    async: false
                });
        } else {
            // Make sure we have the latest JWT, in case the amount changed (donation page)
            // this is cached in songbird locally, it doesn't make an HTTP call
            window.CardinalIntegration.updateJWT();
        }

        let bin = document.querySelector("input[data-3ds-field='CardNumber']").value;
        let monthSelect = document.querySelector("select[data-3ds-field='ExpirationMonth']");
        let expirationMonth = monthSelect.options[monthSelect.selectedIndex].text;

        let expirationYear = document.querySelector("select[data-3ds-field='ExpirationYear']").value;
        let nameOnAccount = document.querySelector("input[data-3ds-field='NameOnAccount']").value;
        let csv2 = document.querySelector("input[data-3ds-field='CardCode']").value;

        let isMoto = document.getElementById("ThreeDSIsMoto").value;
        let transactionOrderNumber = document.getElementById("ThreeDSTransactionOrderNumber").value;

        let orderChannel = "S"; // S = eCommerce per docs https://cardinaldocs.atlassian.net/wiki/spaces/CC/pages/32950/Request+Objects

        if (isMoto === "True")
            orderChannel = "M"; // M = MOTO (Mail Order Telephone Order)

        if (bin === "")
            throw "Credit card number cannot be empty";

        let order = {
            OrderDetails: {
                OrderNumber: transactionOrderNumber,
                OrderChannel: orderChannel
            },
            Consumer: {
                Account: {
                    AccountNumber: bin,
                    ExpirationMonth: expirationMonth,
                    ExpirationYear: expirationYear,
                    NameOnAccount: nameOnAccount,
                    CardCode: csv2
                }
            }
        };

        Cardinal.start("cca",order);
    }
}

//Register on for access from the global DOM (this file is our application entry point)
window.CardinalIntegration = new CardinalIntegration();
