// This class holds the code which handles event triggers from cardinal

export class CardinalEvents {
    constructor() {
    }

    // We could use this to enable/disable the submit button so that it cant be clicked when cardinal is being configured
    // this might be a good idea, so we can handle slow connections
    static onSetupComplete(setupCompleteData) {
        //TODO - enable the checkout button
        //console.log(setupCompleteData);

    }

    static onPaymentsValidated(data, jwt) {
        switch (data.ActionCode) {
            case "SUCCESS":
                // Handle successful transaction, send JWT to backend to verify
                // there are a few fields i think we should be checking here.
                document.getElementById("ThreeDSJWTResult").value = jwt;
                {
                    let event = new CustomEvent("doSubmit", { bubbles: true });
                    document.dispatchEvent(event);
                }
                break;

            case "NOACTION":
                document.getElementById("ThreeDSJWTResult").value = jwt;
                // TODO figure out what we should be doing, we should check some of the response data
                // and determine if we continue or not.
                document.getElementById("ThreeDSJWTResult").value = jwt;
                {
                    let event = new CustomEvent("doSubmit", { bubbles: true });
                    document.dispatchEvent(event);
                }
                break;

            case "FAILURE":
                // Handle failed transaction attempt
                CardinalEvents.ReEnableCommitButtons();
                alert("Authentication was not successful. Please try another payment method.");
                break;

            case "ERROR":
                //TODO re-enable the submit button if we can and display the error to the end user
                CardinalEvents.ReEnableCommitButtons();
                // an alert is not correct, we need to add it to the UI.
                // we should have some sort of service in the UI which allows us to push errors into it, so they display in the correct place,
                // but i reckon we'll just have to hack this
                alert(data.ErrorDescription);
                // Handle service level error
                break;
        }
        //TODO -  we need to review the results that come back, see email from cardinal guy and determine if we show some errors to the end user or we continue.
        //console.log("---------DATA from Cardinal------------");
        //console.log(data);
        //console.log(jwt);
    }

    static ReEnableCommitButtons() {
        //Find the save/submit buttons and re-enable them.
        let saveButton = document.querySelector("input[ID$='_SaveButton']");
        if (saveButton) {
            saveButton.disabled = false;
        }
        let submitButton = document.querySelector("input[ID$='_SubmitCartOrderButton']");
        if (submitButton) {
            submitButton.disabled = false;
        }

    }
}